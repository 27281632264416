import React from "react"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import Navbar from "./Navbar"
import BannerCareer from "../choc/BannerCareer"
import CookieConsent, { Cookies } from "react-cookie-consent"

const Layout = ({ title, description, children }) => {
  return (
    <>
      <Helmet defaultTitle="VeoMed" titleTemplate="%s | VeoMed">
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={description ? description : "Default description"}
        />
        <title>{title}</title>
        <script type="application/ld+json">
          {`
         {
            "@context": "https://schema.org",
            "@type": "Dentist",
            "name": "VeoJadeKlinik - Dr Jann Voltmann und Kollegen",
            "description": "Kieferchirurgie und Ästhetik. Modernste Medizinische Verfahren in der Jadeklinik und Chirurgische Eingriffe und minimalinvasive Ästhetik bei VeoAesthetics",
            "slogan": "Hightech und Verantwortung. Für Zahn, Kiefer und Gesicht.",
            "openingHours": "Mo-Fr 8:00-18:00",
            "telephone": "+49 04421-42033",
            "email": "info@veo-med.com",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Wilhelmshaven",
              "addressRegion": "NIE",
              "postalCode": "26382",
              "streetAddress": "Ebertstraße 80"
            },
            "paymentAccepted": "Cash, Credit Card",
            "priceRange": "$$",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 4.5,
              "reviewCount": 22
            },
            "image": "https://veo-med.com/static/e0f34b02770e23fcddf698d1e34305c2/cf7ee/IMG_0017.webp"

          }`}
        </script>
      </Helmet>

      <Navbar
        linkKey="nav-link"
        links={[
          {
            linkText: "Home",
            link: "/",
            isExternal: false,
          },
          ,
          {
            linkText: "Jadeklinik",
            link: "/jadeklinik",
            isExternal: false,
          },
          {
            linkText: "Implantate",
            link: "/dental/",
            isExternal: false,
          },
          {
            linkText: "Aesthetics",
            link: "/aesthetics",
            isExternal: false,
          },
        ]}
        linkKey1="nav-info"
        links1={[
          {
            linkText: "Kontakt",
            link: "/kontakt",
            isExternal: false,
          },
          {
            linkText: "Impressum",
            link: "/impressum",
            isExternal: false,
          },

          {
            linkText: "Datenschutz",
            link: "/datenschutz",
            isExternal: false,
          },
        ]}
      />

      <main>{children}</main>

      <Footer
        linkKey="nav-link"
        links={[
          {
            linkText: "Home",
            link: "/",
            isExternal: false,
          },
        ]}
        linkKey1="nav-linkpersonal"
        links1={[
          {
            linkText: "Jadeklinik",
            link: "/jadeklinik",
            isExternal: false,
          },
          {
            linkText: "Implantate",
            link: "/dental/",
            isExternal: false,
          },
        ]}
        linkKey2="nav-linportfolio"
        links2={[
          {
            linkText: "Aesthetics",
            link: "/aesthetics/",
            isExternal: false,
          },
          {
            linkText: "Hyaluron und Botox",
            link: "/aesthetics/hyaluron/",
            isExternal: false,
          },
          {
            linkText: "Facelifts",
            link: "/aesthetics/facelift",
            isExternal: false,
          },
          {
            linkText: "Facelift Max",
            link: "/aesthetics/faceliftmax",
            isExternal: false,
          },
          {
            linkText: "Facelift SMAS",
            link: "/aesthetics/faceliftmas",
            isExternal: false,
          },
          {
            linkText: "Liquid Facelift",
            link: "/aesthetics/faceliftliquid",
            isExternal: false,
          },
          {
            linkText: "Facelift Mannequin",
            link: "/aesthetics/faceliftmannequin",
            isExternal: false,
          },
        ]}
        linkKey3="nav-info"
        links3={[
          {
            linkText: "Kontakt",
            link: "/kontakt",
            isExternal: false,
          },
          {
            linkText: "Impressum",
            link: "/impressum",
            isExternal: false,
          },

          {
            linkText: "Datenschutz",
            link: "/datenschutz",
            isExternal: false,
          },
        ]}
        textColor={"brand.gray.900"}
      />
    </>
  )
}

export default Layout
