import { Box, Button, Text, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import React from "react"

export const Modal3med = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position="relative"
          top="50px"
          left={{ base: "12%", md: "25%" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mt={"15vh"}
        >
          <Box
            w={{ base: "80vw", md: "50vw" }}
            mt={"15vh"}
            ml={{ base: "12vw", md: "25vw" }}
            as={motion.div}
            position="fixed"
            zIndex="99999"
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={8}
            top={"0"}
            boxShadow="base"
            bg={"#ffffff"}
            borderRadius="11px"
          >
            <Heading
              fontSize="3xl"
              fontWeight="normal"
              color="brand.violet.light"
            >
              Mund-, Kiefer- und Gesichtschirurgie
            </Heading>
            <Text mt={8} fontSize={"lg"}>
              Die Mund-, Kiefer- und Gesichtschirurgie beschäftigt sich mit der
              Diagnose, Behandlung und Prävention von Erkrankungen und
              Verletzungen des Kiefers und der Gesichtsregion.
              <br /> <br />
              Dabei können sowohl Kieferfehlstellungen als auch Zahn-, Kiefer-
              und Gesichtsverletzungen behandelt werden. <br />
              Die Kieferchirurgie umfasst unter anderem operative Eingriffe wie
              die Entfernung von Weisheitszähnen, die Korrektur von
              Kieferfehlstellungen oder die Rekonstruktion von Gesichtsknochen.
              <br /> <br />
              Ziel der Kieferchirurgie ist es, die Gesundheit und
              Funktionsfähigkeit des Kiefers und der Gesichtsregion
              wiederherzustellen, um eine optimale Lebensqualität zu
              ermöglichen.
            </Text>
            <Button
              mt={12}
              px={6}
              pt={3}
              pb={4}
              fontSize="xs"
              textAlign="center"
              variant={"solid"}
              onClick={() => setToggle(false)}
            >
              Schliessen
            </Button>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
