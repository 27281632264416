import { Box } from "@chakra-ui/react"
import React from "react"
import ImgTextModule from "./ImgTextModule"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import { Grid, GridItem, Text, Button } from "@chakra-ui/react"

function ZweiBewerbungen() {
  return (
    <Grid
      px={{ base: 6, md: 12, lg: 6, xl: 0 }}
      templateColumns={{
        base: "repeat(6, 1fr)",
        md: "repeat(6 , 1fr)",
        lg: "repeat(12, 1fr)",
        xl: "repeat(12, 1fr)",
      }}
      gap={{ base: 0, md: 0, lg: 8 }}
      my={0}
    >
      <GridItem colSpan="6" mb={8}>
        <ImgTextModule
          src={
            "493557_Dentist_Patient_Sterile_Equipment_Dental_Clinic_By_M_ART_production_Artlist_HD.mp4"
          }
          delay={0.3}
          h="100%"
          duration={1}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          mediaOpacity={0.9}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.tan.ulight" fontSize="lg">
                Zahnmedizinische Fachangestellte
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "4xl", lg: "4xl" }}
              >
                ZFA / MFA
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.tan.ulight"
                mb={12}
                maxW={{ base: "100%", lg: "100%" }}
                fontSize="lg"
              >
                Assistenz bei dentalen und plastisch ästhetischen Eingriffen im
                Kopf- und Halsbereich.
              </Text>
              <RouterLink isExternal={false} link={"/bewerbung"}>
                <Button
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  bg="brand.white"
                >
                  Bewerben
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </GridItem>

      <GridItem colSpan="6" mb={8}>
        <ImgTextModule
          src={"112007.mp4"}
          delay={0.3}
          h="100%"
          duration={1}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          mediaOpacity={0.8}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.tan.ulight" fontSize="lg">
                Operationstechnische Assistenz
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "4xl", lg: "4xl" }}
              >
                OTA
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.tan.ulight"
                mb={12}
                maxW={{ base: "100%", lg: "100%" }}
                fontSize="lg"
              >
                Vorbereitung von Operationen, inklusive Instrumentenaufbereitung
                und Sterilisation. Assistenz bei Operationen in der
                MKG-Chirurgie und der plastisch-ästhetischen Chirurgie im Kopf-
                und Halsbereich.
              </Text>
              <RouterLink isExternal={false} link={"/bewerbung"}>
                <Button
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  bg="brand.white"
                >
                  Bewerben
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </GridItem>
    </Grid>
  )
}

export default ZweiBewerbungen
